<template>
    <v-row class="mx-0">
        <v-col>
            <span class="newsTitle px-5">News</span>
        </v-col>
    </v-row>
    <v-row class="px-5 mx-0">
        <v-col class="px-3">
            <v-sheet height="120" class="newsImg" :style="'background: url(/img/newsitem1.png); background-size: contain;'">
            </v-sheet>
            
            <span class="newsItemTitle mt-1 ml-2">Allergy season</span>
            <span class="newsItemText ml-2">12/04/2023</span>
        </v-col>
        <v-col class="px-3">
            <v-sheet height="120" class="newsImg" :style="'background: url(/img/newsitem2.png); background-size: contain;'">
            </v-sheet>
            <span class="newsItemTitle mt-1 ml-2">Plant based health</span>
            <span class="newsItemText ml-2">12/04/2023</span>
        </v-col>
    </v-row>
</template>

<script setup lang="ts">

</script>

<style>
.newsImg {
    border-radius: 20px;
}

.newsTitle {
    display: inline-block;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height */

    display: flex;
    align-items: center;
}

.newsItemTitle {
    display: inline-block;
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
}

.newsItemText {
    display: block;
    font-family: 'Poppins';
    font-size: 11px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
}
</style>