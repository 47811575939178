import {defineStore} from 'pinia';
import {ref} from 'vue';

export const useSnackbarStore = defineStore('snackbar', () => {
    // Use a Map to store snackbars
    const snackbars = ref(new Map<string, { message: string, type: string }>());

    // Function to show a snackbar notification
    const showSnackbar = (message: string, type = 'success'): void => {
        const snackbarId = self.crypto.randomUUID();
        snackbars.value.set(snackbarId, {message, type});

        // Automatically remove the snackbar after a timeout
        setTimeout(() => removeSnackbar(snackbarId), 3000);
    };

    // Function to remove a snackbar by its ID
    const removeSnackbar = (snackbarId: string): void => {
        snackbars.value.delete(snackbarId);
    };

    const successSnackbar = (message: string) => showSnackbar(message, 'success');
    const errorSnackbar = (message: string) => showSnackbar(message, 'error');
    const warningSnackbar = (message: string) => showSnackbar(message, 'warning');
    const infoSnackbar = (message: string) => showSnackbar(message, 'info');

    return {
        snackbars,
        showSnackbar,
        removeSnackbar,
        successSnackbar,
        errorSnackbar,
        warningSnackbar,
        infoSnackbar
    };
});
