<template>
    <v-container class="text-center">
        <v-row class="mt-2" v-if="state.order">
            <v-col class="pa-2 text-center" cols="12">
                <v-card>
                    <v-card-title>
                        <p class="p-text-lg">Card Payment</p>
                    </v-card-title>
                    <v-card-text v-if="state.paymentSession">
                        <swan-pay-container :session="state.paymentSession" @payment:success="paymentSuccess()"
                    @payment:error="paymentError()" />
                    </v-card-text>
                    <v-card-text class="text-center" v-else>
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                        <p>Loading Payment Session...</p>
                    </v-card-text>
                </v-card>
                <v-card v-if="state.paymentInformation !== ''">
                    <v-card-title>
                        <p class="p-text-lg">Payment Information</p>
                    </v-card-title>
                    <v-card-text>
                        <p>{{ state.paymentInformation }}</p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col class="text-center">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { ordersService } from '@/api/services/order';
import { paymentService } from '@/api/services/payment';
import { useSettingsStore } from '@/api/store/settings';

import { swanpayService } from '@/api/services/swanpay';
import { settingsService } from '@/api/services/setting';
import SwanPayContainer from '@/components/SwanPayContainer.vue';

import ND from '@/components/vectors/ND.vue';

const route = useRoute();
const router = useRouter();


const settingsStore = useSettingsStore();

const state = reactive({
    order: null as any,
    loaded: false,
    paymentData: {} as any,
    paymentSession: null as any,
    paymentInformation: "" as string,
});

const getPaymentInformation = () => {
    settingsService.fetch('payment_information').then((response: any) => {
        state.paymentInformation = response.value;
    }).catch((error: any) => {
        console.log(error);
    });
}

const getOrder = () => {
    settingsStore.loading = true;
    ordersService.show(+route.params.id).then((response) => {
        state.order = response;
        state.paymentData.order_id = state.order.id;
        state.paymentData.reference = "ORDER" + state.order.id;
        state.paymentData.amount = state.order.amount_outstanding;
        getSession();
    }).catch((error) => {
        console.log(error);
    }).finally(() => {
        settingsStore.loading = false;
        state.loaded = true;
    });
}

const getSession = () => {
    swanpayService.getPaymentSession(state.paymentData)
        .then((response) => {
            state.paymentSession = {
                id: response.payment_session.external_session_id,
                currency: response.payment_session.currency,
                amount: response.payment_session.amount,
                sessionData: response.payment_session.session_data
            }
        })
        .catch((error) => {
            console.error(error);

            paymentError();
        })
        .finally(() => {
            state.loaded = true;
            settingsStore.loading = false;
        });
}

const paymentSuccess = () => {
    state.order.status = 'processing';
    router.push({ name: 'order-view', params: { id: state.order.id } });
}

const paymentError = () => {
    console.log('Payment Failed');
}

getPaymentInformation();
getOrder();
</script>

<style>
.tick-icon-outer {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: absolute;
    margin-top: -20px;
    margin-left: 70px;
}
</style>