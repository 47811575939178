<template>
  <v-container class="pa-0" :style="'height:55px;color: ' + state.text + ';background-color: ' + state.bg">
    <v-row class="pt-2" no-gutters>
      <v-col cols="2" class="d-flex justify-start align-center">
        <!-- v-icon start icon="fa-arrow-left d-block" @click="router.back()" class="ml-4" size="25"></v-icon-->
        <back-arrow @click="router.back()" class="ml-5"></back-arrow>
      </v-col>
      <v-col class="d-flex justify-center align-center">
        <span class="d-block p-head-title ml-1 mt-1 text-capitalize">{{ state.title }}</span>
      </v-col>
      <v-col cols="2" class="d-flex justify-end align-center">
        <div v-if="state.showCart" class="mr-3 pa-1" offset-x="100">
          <v-badge :content="cartStore.item_count" color="red" v-if="cartStore.item_count > 0">
            <v-icon end icon="fa-shopping-cart d-block" class="mr-4" size="20"
                    @click="router.push({name: 'shopping-cart'})">
            </v-icon>
          </v-badge>
          <v-icon end icon="fa-shopping-cart d-block" class="mr-4" size="20"
                  @click="router.push({name: 'shopping-cart'})" v-else/>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts" setup>
import {useRouter} from 'vue-router';
import {reactive, watch} from 'vue';
import {useCartStore} from '@/api/store/cart';
import {useSettingsStore} from '@/api/store/settings';
import BackArrow from '../vectors/BackArrow.vue';

const settingsStore = useSettingsStore();
const cartStore = useCartStore();
const router = useRouter();

const state = reactive({
  title: router.currentRoute.value.meta.title ? router.currentRoute.value.meta.title : router.currentRoute.value.name,
  bg: router.currentRoute.value.meta.colorTitleBar ? router.currentRoute.value.meta.colorTitleBar : settingsStore.settings.primaryColor,
  text: router.currentRoute.value.meta.textTitleBar ? router.currentRoute.value.meta.textTitleBar : '#FFF',
  showCart: router.currentRoute.value.meta.showCart ? router.currentRoute.value.meta.showCart : false,
});

watch(() => router.currentRoute.value.name, (val) => {
  state.title = router.currentRoute.value.meta.title ? router.currentRoute.value.meta.title : val;
  state.bg = router.currentRoute.value.meta.colorTitleBar ? router.currentRoute.value.meta.colorTitleBar : settingsStore.settings.primaryColor;
  state.text = router.currentRoute.value.meta.textTitleBar ? router.currentRoute.value.meta.textTitleBar : '#FFF';
  state.showCart = router.currentRoute.value.meta.showCart ? router.currentRoute.value.meta.showCart : false;

});

</script>