<template>
  <v-app-bar
      color="white"
      density="compact"
      app fixed
  >
    <template v-slot:prepend>
      <v-btn icon>
        <v-icon class="primary-color" icon="fa-bars" size="20"/>
      </v-btn>
      <v-btn icon>
        <v-icon class="primary-color" icon="fa-search" size="20"/>
      </v-btn>
    </template>

    <v-app-bar-title class="text-center">
      <v-btn icon @click="router.push({ name: 'home' })">
        <v-icon class="primary-color" icon="fa-home" size="25"/>
      </v-btn>
    </v-app-bar-title>

    <template v-slot:append>
      <v-btn icon @click="router.push({ name: 'notifications' })">
        <v-icon class="primary-color" icon="fa-bell" size="20"/>
      </v-btn>

      <v-btn icon @click="router.push({ name: 'client' })">
        <v-icon class="primary-color" icon="fa-user" size="20"/>
      </v-btn>
    </template>
  </v-app-bar>
</template>

<script lang="ts" setup>
import {useRouter} from 'vue-router';

const router = useRouter();
</script>