<template>
  <v-container>
    <v-row v-if="settingsStore.settings.shopFeaturedItems && state.featuredProducts?.length > 0">
      <v-col cols="9"><p class="p-text-lg">Shop Our Range</p></v-col>
      <v-col>
        <p class="p-text-sm text-right mt-1 mr-1 text-pink" @click="router.push({ name: 'clinic-shop' })">
          <u><a>Show All</a></u>
        </p>
      </v-col>
    </v-row>
    <v-row v-if="settingsStore.settings.shopFeaturedItems && state.featuredProducts?.length > 0" class="mb-3 px-5">
      <v-col v-for="item in state.featuredProducts" :key="item.id" class="px-1 mt-2">
        <v-sheet color="white" class="pa-1"><v-img height="90" width="110" :src="getFeaturedImage(item)" cover class="text-white mx-auto mb-2 f-product" text-center
          @click="router.push({ name: 'shop-product', params: { id: item.id } })" /></v-sheet>

        <div class="p-text-xs font-weight-bold ms-1 mb-1 mt-2">
          {{ item.name }}
        </div>
        <div class="p-text-xs font-weight-light ms-1 mb-1">
          
        </div>
        <div class="p-text-xs font-weight-bold ms-1 mb-1">
          {{ item.price_display }}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="9"><p class="p-text-lg">Shop Our Categories</p></v-col>
      <v-col><p class="p-text-sm text-right mt-1 mr-1 text-pink" @click="router.push({ name: 'clinic-shop' })"><u><a>Show All</a></u></p></v-col>
    </v-row>
    <v-row class="px-4 mb-5 mt-5">
      <v-col cols="3"
      @click="router.push({ name: 'clinic-shop', params: { category: 'services'} })">
        <v-img src="/img/categoriesImg1.png" height="80" width="80"/>
        <p class="p-text-cat text-center">Services</p>
      </v-col>
      <v-col cols="3"
      @click="router.push({ name: 'clinic-shop', params: { category: 'packages'} })">
        <v-img src="/img/categoriesImg2.png" height="80" width="80"/>
        <p class="p-text-cat text-center">Packages</p>
      </v-col>
      <v-col cols="3"
      @click="router.push({ name: 'clinic-shop', params: { category: 'programmes'} })">
        <v-img src="/img/categoriesImg3.png" height="80" width="80"/>
        <p class="p-text-cat text-center">Programmes</p>
      </v-col>
      <v-col cols="3"
      @click="router.push({ name: 'clinic-shop', params: { category: 'products'} })">
        <v-img src="/img/categoriesImg4.png" height="80" width="80"/>
        <p class="p-text-cat text-center">Products</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col><p class="p-text-lg">Shop By Supplier</p></v-col>
    </v-row>
    <v-row>
      <v-col class="px-5 py-2">
          <v-img src="/img/ND.png" @click="router.push({name: 'natural-dispensary'})"/>
      </v-col>
    </v-row>
    

  </v-container>
</template>

<script lang="ts" setup>
import router from '@/router';
import { reactive } from 'vue';
import { useSettingsStore } from '@/api/store/settings';
const settingsStore = useSettingsStore();

import { productService } from '@/api/services/product';

const state = reactive({
  featuredProducts: [] as any[],
});

settingsStore.loading = true;
productService.getFeaturedProducts().then((response) => {
  state.featuredProducts = response.data;
  settingsStore.loading = false;
});

const getFeaturedImage = (item: any) => {
  if (item.images.length > 0) {
    return item.images[0].full;
  } else {
    return '/img/placeholder.png';
  }
}
</script>

<style>
.dot {
  height: 11px;
  width: 11px;
  border-radius: 50%;
  margin-left: 10px;
  display: inline-block;
}

.dot.services-col {
  background-color: #1EBCD5;
}

.dot.packages-col {
  background-color: #70E591;
}

.dot.products-col {
  background-color: #C671C2;
}

.f-product {
  border-radius: 20px;
}
</style>