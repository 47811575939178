<template>
    <v-container class="py-3 mt-3 px-5" v-if="state.item" style="padding-left:0px;padding-right:0px;">
        <v-sheet width="100%" height="300" v-if="!state.item.images || state.item.images.length == 0" color="#EEE"></v-sheet>
        <v-img width="100%" height="300" :src="state.item.images[0].full" v-else></v-img>
        <v-card class="mt-3 mx-0">
            <v-card-title class="item-header"><b>{{ state.item.name }}</b></v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="1"></v-col>
                    <v-col cols="4">
                        <p class="panel-title">Brand:</p>
                    </v-col>
                    <v-col cols="6">
                        <p class="panel-content">{{ state.item.brand?.name }}</p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="1"></v-col>
                    <v-col cols="4">
                        <p class="panel-title">Product ID: </p>
                    </v-col>
                    <v-col cols="6">
                        <p class="panel-content">{{ state.item.sku }}</p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="1"></v-col>
                    <v-col cols="4">
                        <p class="panel-title">Format: </p>
                    </v-col>
                    <v-col cols="6">
                        <p class="panel-content">{{ state.item.package_qty }} {{ state.item.package_unit }}</p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="1"></v-col>
                    <v-col cols="4">
                        <p class="panel-title">Price </p>
                    </v-col>
                    <v-col cols="6">
                        <p class="panel-content"><b>£{{ state.item.price.toFixed(2) }}</b></p>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-expansion-panels variant="accordion">
                <v-expansion-panel v-for="itemInfo in state.PanelData" :key="itemInfo.title">
                    <v-expansion-panel-title>
                        <p class="panel-title">{{ itemInfo.title }}</p>
                        <template v-slot:actions>
                            <v-icon class="panel-arrow" icon="fa-chevron-down">
                            </v-icon>
                        </template>
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                        <div class="my-2 text-subtitle-1" v-html="itemInfo.content">
                            
                        </div>
                    </v-expansion-panel-text>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card>
        <v-card class="py-2 mb-2">
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-btn class="text-white add-to-cart-btn mx-auto d-inline b-round-20" variant="outlined" @click="addProduct" style="margin-top:8px;width:200px">
                            <span>Add to cart</span>
                        </v-btn>
                    </v-col>
                    <v-col>
                        <quantity-editor v-model="state.qty" />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script lang="ts" setup>
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { productService } from '@/api/services/product';
import { cartService } from '@/api/services/cart';
import { useSettingsStore } from '@/api/store/settings';
import { useCartStore } from '@/api/store/cart';
import QuantityEditor from '@/components/QuantityEditor.vue';

const settingsStore = useSettingsStore();
const cartStore = useCartStore();

const route = useRoute();
const router = useRouter();

const state = reactive({
    item: null as any,
    qty: 1 as number,
    fav: false as boolean,
    PanelData: [] as any[],
});

settingsStore.loading = true;
productService.getProduct(+route.params.id).then((response) => {
    state.item = response.data;
    router.currentRoute.value.meta.title = state.item.name;
}).catch((err) => {
    //router.push({ name: 'shop' });
}).finally(() => {
    settingsStore.loading = false;
});

const addProduct = () => {
    settingsStore.loading = true;
    cartStore.addItem(null, state.item.id, state.qty).then((res) => {
        console.log(res);
        router.push({ name: 'shopping-cart' });
    }).catch((error) => {
        settingsStore.error = true;
        settingsStore.error_message = "This item may be out of stock or no longer available from the supplier."
    }).finally(() => {
        settingsStore.loading = false;
    });
};


</script>

<style>
.add-to-cart-btn {
    background-color: #C671C2;
}

.panel-arrow {
    color: #1EBCD5;
}

.item-header {
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    text-align: left;
}

.panel-title {
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #838383;
}

.panel-content {
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
}

.v-col {
    padding: 4px !important;
}</style>