<template>
  <v-container class="px-1">
    <v-row class="my-0 py-1" no-gutters>
      <v-col class="my-1 px-1" cols="12">
        <v-text-field v-model="state.search" density="compact" hide-details placeholder="Search Store"
                      rounded variant="solo" @change="FilterItems">
          <template v-slot:prepend-inner>
            <v-icon class="mt-1" color="grey" icon="fa-search" size="12"/>
          </template>
        </v-text-field>
      </v-col>
      <v-col class="my-0 px-1" cols="6">
        <!--prepend-inner-icon="fa:fas fa-search"-->
        <ButtonFilterSelect
            v-model="sortTabSelectedValue"
            :is-tab-selected="filterSelected === 'sort'"
            :items="sortValues"
            :multiple="false"
            :text="sortTabSelectedValue"
            icon="sort-icon"
            single-line
            @click="()=> filterSelected = 'sort'"
        />
      </v-col>
      <v-col class="my-0 px-1" cols="6">
        <ButtonBase
            :custom-class="['transparent text-black', {'secondary-button text-white': filterSelected === 'clear'}]"
            text="Clear Filters"
            width="100%"
            @click="resetFilters"
        />
      </v-col>
    </v-row>

    <v-row class="px-2 mt-1" no-gutters>

      <v-col v-for="item in state.items" :key="item.id" class="pa-1" cols="6">
        <v-card v-if="item.item_type == 'product'" class="mx-auto pt-2 px-2" height="250"
                @click="router.push({ name: 'shop-product', params: { 
                  id: item.id, 
                  type: item.type === 'clinic_product'? 'clinic': 'natural-dispensary' 
                }
               })"
        >
          <v-img :src="getFeaturedImage(item)" class="text-white text-right align-end" cover height="120">
            <v-btn size="40" variant="flat" @click.stop="addProductToCart(item)">
              <v-icon :color="settingsStore.settings.buttonColor" icon="fa fa-cart-plus" size="20"></v-icon>
            </v-btn>
          </v-img>

          <v-card-text>
            <div class="p-text font-weight-bold ms-1 mb-2">
              {{ item.name }}
            </div>
            <div class="p-text font-weight-light ms-1 mb-2">
              {{ item.slot3 }}
            </div>
            <div class="p-text font-weight-bold ms-1 mb-2">
              £{{ item.price.toFixed(2) }}
            </div>
          </v-card-text>
        </v-card>
        <v-card v-else-if="item.item_type == 'service'" class="mx-auto pt-2 px-2" height="250"
                @click="router.push({ name: 'shop-service', params: { id: item.id } })"
        >
          <v-img :src="item.image?.file?.file_path" class="text-white" cover height="120"/>
          <v-card-text>
            <div class="p-text font-weight-bold ms-1 mb-2">
              {{ item.name }}
            </div>
            <div class="p-text font-weight-light ms-1 mb-2">
              {{ item.booking_options ? item.booking_options.duration : '0' }} mins
            </div>
            <div class="p-text font-weight-bold ms-1 mb-2">
              {{ item.price_display }}
            </div>
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>

  </v-container>
</template>

<script lang="ts" setup>
import {computed, reactive, shallowRef} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {useCartStore} from '@/api/store/cart';
import {productService} from '@/api/services/product';
import {servicesService} from '@/api/services/service';
import {useSettingsStore} from '@/api/store/settings';
import ButtonBase from "@/components/custom/ButtonBase.vue";
import ButtonFilterSelect from "@/components/custom/ButtonFilterSelect/ButtonFilterSelect.vue";

const settingsStore = useSettingsStore();
const cartStore = useCartStore();

const router = useRouter();
const route = useRoute();

const category = route.params.category;

const state = reactive({
  items: [] as any[],
  search: "" as string,
  sort_asc: true as boolean,
  page: 1 as number,
});

const sortValues = [
  {
    name: 'asc',
    label: 'Asc'
  },
  {
    name: 'desc',
    label: 'Desc'
  }
]

const filterSelected = shallowRef('clear');

const sortTabSelectedValue = shallowRef({
  name: 'Sort',
  label: 'Sort'
})

const getFeaturedImage = (item: any) => {
  if (item.images.length > 0) {
    return item.images[0].full;
  }
  return '/img/placeholder.png';
}

const resetFilters = () => {
  state.search = "";
  state.sort_asc = true;
  filterSelected.value = 'clear';
}

const sortItems = () => {
  if (state.sort_asc) {
    state.items.sort((a, b) => {
      return a.id - b.id;
    });
  } else {
    state.items.sort((a, b) => {
      return b.id - a.id;
    });
  }
}



const loadNextData = async () => {
  state.page += 1;
  settingsStore.loading = true;
  let filters = "page=" + state.page;
  loadItems(filters);
};

const loadItems = async (filter: string | null) => {

  state.items = [];

  if (!category || category === 'products') {
    settingsStore.loading = true;
    await productService.index(null, filter).then((response) => {
      const data = response.data;
      data.forEach(function (element: any) {
        if (!element.visible_in_app) return;
        element.item_type = 'product';
        state.items.push(element);
      });
    });
    settingsStore.loading = false;
  }

  if (!category || category === 'services') {
    settingsStore.loading = true;
    await servicesService.index(filter).then((response) => {
      const data = response.data;
      data.forEach(function (element: any) {
        
        if (!element.client_visibility) return;
        element.item_type = 'service';
        state.items.push(element);
      });
    });
    settingsStore.loading = false;
  }
}

const FilterItems = () => {
  let filters = null;
  if (state.search != "") {
    filters = "search=" + state.search;
  }

  loadItems(filters);
}

const addProductToCart = (item: any) => {
  settingsStore.loading = true;
  cartStore.addItem(0, item.id, 1).then((res: any) => {
    router.push({name: 'shopping-cart'});
  }).catch((error: any) => {
    settingsStore.error = true;
    settingsStore.error_message = "This item may be out of stock or no longer available from the supplier."
  }).finally(() => {
    settingsStore.loading = false;
  });
}

loadItems(null);
</script>