<template>
    <v-container v-if="state.service">
        <v-sheet class="px-3 ma-1 text-center sw-nav" color="white">
            <v-img :src="state.service.coverImageUrl" height="150"/>
        </v-sheet>
        <v-row>
            <v-col>
                <v-sheet class="pa-3 ma-1 text-center sw-nav" color="white">
                    <span class="p-head-title">{{ state.service.name }}</span>
                </v-sheet>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="2"></v-col>
            <v-col>
                <v-sheet class="px-3 ma-1 text-center sw-nav" color="white">
                    <span class="p-head-sm">{{ state.service.booking_options.duration }} mins</span>
                </v-sheet>
            </v-col>
            <v-col>
                <v-sheet class="px-3 ma-1 text-center sw-nav" color="white">
                    <span class="p-head-sm">{{ state.service.price_display }}</span>
                </v-sheet>
            </v-col>
            <v-col cols="2"></v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-sheet class="px-3 ma-1 text-center sw-nav" color="white">
                    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="14" viewBox="0 0 56 14" fill="none">
                    <path d="M5.88428 6.72764C6.22803 6.72764 6.50303 6.63014 6.74365 6.40264C6.98428 6.17514 7.0874 5.91514 7.0874 5.59014C7.0874 5.26514 6.98428 5.00514 6.74365 4.77764C6.50303 4.55014 6.22803 4.45264 5.88428 4.45264C5.54053 4.45264 5.26553 4.55014 5.0249 4.77764C4.78428 5.00514 4.68115 5.26514 4.68115 5.59014C4.68115 5.91514 4.78428 6.17514 5.0249 6.40264C5.26553 6.63014 5.54053 6.72764 5.88428 6.72764ZM5.88428 12.0901C7.39678 10.7901 8.53115 9.58764 9.25303 8.51514C9.9749 7.44264 10.353 6.50014 10.353 5.68764C10.353 4.42014 9.90615 3.38014 9.04678 2.56764C8.1874 1.75514 7.12178 1.36514 5.88428 1.36514C4.64678 1.36514 3.58115 1.75514 2.72178 2.60014C1.8624 3.44514 1.41553 4.45264 1.41553 5.72014C1.41553 6.53264 1.79365 7.47514 2.51553 8.54764C3.2374 9.62014 4.40615 10.7901 5.88428 12.0901ZM5.88428 13.3901C4.02803 11.8951 2.65303 10.5301 1.75928 9.26264C0.865527 7.99514 0.384277 6.82514 0.384277 5.72014C0.384277 4.09514 0.934277 2.79514 2.03428 1.85264C3.13428 0.910137 4.44053 0.390137 5.88428 0.390137C7.32803 0.390137 8.63428 0.877637 9.73428 1.82014C10.8343 2.76264 11.3843 4.09514 11.3843 5.72014C11.3843 6.79264 10.9374 7.99514 10.0093 9.26264C9.08115 10.5301 7.74053 11.8951 5.88428 13.3901Z" fill="#70E591" stroke="#70E591" stroke-width="0.3"/>
                    <path d="M30.786 12.3901C29.4193 12.3901 28.086 12.0568 26.7526 11.3901C25.4193 10.7235 24.2193 9.8568 23.1526 8.79014C22.0526 7.72347 21.186 6.52347 20.5193 5.19014C19.8526 3.8568 19.5193 2.49014 19.5193 1.12347C19.5193 0.92347 19.586 0.72347 19.7526 0.590137C19.9193 0.456803 20.0526 0.390137 20.2526 0.390137H22.586C22.7526 0.390137 22.886 0.456803 22.986 0.556803C23.086 0.656803 23.186 0.790137 23.2193 0.990137L23.686 3.09014C23.7193 3.2568 23.7193 3.39014 23.686 3.52347C23.6526 3.6568 23.586 3.7568 23.5193 3.8568L21.8526 5.52347C22.486 6.5568 23.186 7.4568 23.9526 8.22347C24.7193 8.99014 25.586 9.6568 26.586 10.1901L28.186 8.5568C28.286 8.42347 28.4193 8.3568 28.5526 8.29014C28.686 8.22347 28.8526 8.22347 28.986 8.2568L30.986 8.69014C31.1526 8.72347 31.286 8.79014 31.386 8.9568C31.4526 9.0568 31.5193 9.22347 31.5193 9.39014V11.6568C31.5193 11.8568 31.4526 12.0568 31.286 12.1901C31.1526 12.3235 30.986 12.3901 30.786 12.3901ZM21.3526 4.59014L22.7193 3.22347L22.3193 1.39014H20.5193C20.5193 1.82347 20.586 2.29014 20.7193 2.82347C20.8526 3.3568 21.0526 3.92347 21.3526 4.59014ZM27.486 10.6568C27.9526 10.8568 28.4526 11.0235 28.9526 11.1568C29.486 11.2901 30.0193 11.3568 30.5193 11.3901V9.62347L28.786 9.29014L27.486 10.6568Z" fill="#70E591" stroke="#70E591" stroke-width="0.2"/>
                    <path d="M41.2489 12.7825C40.9391 12.7825 40.6681 12.6663 40.4357 12.434C40.2033 12.2016 40.0872 11.9305 40.0872 11.6207V1.55192C40.0872 1.24211 40.2033 0.97103 40.4357 0.738672C40.6681 0.506315 40.9391 0.390137 41.2489 0.390137H51.3178C51.6276 0.390137 51.8986 0.506315 52.131 0.738672C52.3634 0.97103 52.4795 1.24211 52.4795 1.55192V5.73435L55.5776 2.63626V10.5751L52.4795 7.47703V11.6207C52.4795 11.9305 52.3634 12.2016 52.131 12.434C51.8986 12.6663 51.6276 12.7825 51.3178 12.7825H41.2489ZM41.2489 11.6207H51.3178V1.55192H41.2489V11.6207Z" fill="#70E591" stroke="#70E591" stroke-width="0.2"/>
                    </svg>
                </v-sheet>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-sheet class="px-3 ma-1 text-center overflow-y-auto" color="white" height="100">
                    <span class="p-text-sm">{{ state.service.description }}</span>
                    
                </v-sheet>
            </v-col>
        </v-row>

        <v-sheet width="100%" class="mt-1">
        <v-row>
            <v-col class="text-center" cols="12">
                <v-sheet class="px-3 ma-1 text-center sw-nav" color="white">
                <v-btn class="text-white add-to-cart-btn mx-auto d-block b-round-20" variant="outlined" width="200"
                @click="router.push({name: 'book-service', params: { id: state.service.id }})">
                    <span>Book Now</span>
                </v-btn>
                <v-btn class="mx-auto d-block b-round-20 mt-2" variant="outlined" width="200" :color="settingsStore.settings.buttonColor"
                @click="addService()">
                    <span>Add to Cart</span>
                </v-btn>
            </v-sheet>
            </v-col>
        </v-row>
    </v-sheet>
    </v-container>
</template>

<script lang="ts" setup>
import { reactive } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { servicesService } from '@/api/services/service';
import { useSettingsStore } from '@/api/store/settings';
import { useCartStore } from '@/api/store/cart';

const router = useRouter();
const route = useRoute();

const settingsStore = useSettingsStore();
const cartStore = useCartStore();

const state = reactive({
    service: null as any | null,
});

const loadService = async () => {
    settingsStore.loading = true;
    let serviceId = Number(route.params.id);
    state.service = await servicesService.getService(serviceId);
    settingsStore.loading = false;
};

loadService();

const SUPPLIER_INDEX = null; //null = clinic shop

const addService = () => {
    settingsStore.loading = true;
    cartStore.addItem(SUPPLIER_INDEX, state.service.id, 1, "App\\Models\\v3\\ClinicService").then((res) => {
        console.log(res);
        router.push({ name: 'shopping-cart' });
    }).catch((error) => {
        settingsStore.error = true;
        settingsStore.error_message = "This item may be out of stock or no longer available from the supplier."
    }).finally(() => {
        settingsStore.loading = false;
    });
};

</script>