<template>
  <v-container
    :style="'height:calc(100vh + 100px);margin-top:-100px;background-color:' + settingsStore.settings.backgroundColor1">
    <div class="d-flex justify-center mb-5" style="margin-top:200px;">
      <img :src="settingsStore.settings.brandingImg" class="mx-2 pa-2 mt-5" style="max-width: 100%">
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-8 col-12 mx-auto">
        <div class="card z-index-0 fadeIn3 fadeInBottom">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div class="bg-gradient-success shadow-success border-radius-lg py-3 pe-1">
              <h4 class="text-white font-weight-bolder text-center mt-2 mb-0">
                Sign in
              </h4>
            </div>
          </div>
          <div class="card-body">
            <form role="form" class="text-start">
              <v-text-field bg-color="white" density="compact" class="mt-3" label="Email" v-model="state.email"
                :disabled="state.requested_token"></v-text-field>
              <v-text-field bg-color="white" density="compact" class="mt-3" label="Token" v-model="state.token"
                :disabled="!state.requested_token"></v-text-field>
              <div class="text-center" v-if="state.clinics.length > 1">
                <label>Clinic:</label>
                <v-select :items="state.clinics" v-model="state.clinic" :disabled="state.requested_token"></v-select>
              </div>
              <div class="text-center">
                <v-btn :color="settingsStore.settings.buttonColor" class="text-white my-4 mb-2 b-round-20"
                  @click="doLogin(false)">Sign in</v-btn>
              </div>
              <div class="text-center">
                <v-btn color="white" class="text-secondary my-4 mb-2 b-round-20"
                  @click="doLogin(true)">Sign in with SMS</v-btn>
              </div>
            </form>
            <p class="text-red">{{ state.error }}</p>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script lang="ts" setup>
import { defineComponent, reactive } from "vue";
import { useRouter } from "vue-router";
import { loginService } from "@/api/services/login";
import { useSettingsStore } from '@/api/store/settings';
import { useUserStore } from '@/api/store/user';
import { setToken, setUser } from "@/api/services/auth"
import { appService } from '@/api/services/app'

const settingsStore = useSettingsStore();
const userStore = useUserStore();

const state = reactive({
  email: "",
  token: "",
  requested_token: false,
  loading: false,
  error: "",
  clinic: null,
  clinics: []
});

appService.fetchDisplay().then((response: any) => {
  settingsStore.settings = JSON.parse(response.settings);
}).catch((err: any) => {
      console.log(err);
    });

const getSettings = async () => {
  appService.fetchDisplay().then((result: any) => {
    const data = JSON.parse(result.settings);
    settingsStore.settings = data;
    settingsStore.set = true;
    settingsStore.loading = false;
  })
    .catch((error: any) => {
      settingsStore.loading = false;
      settingsStore.set = true;
    });
}

const router = useRouter();
const doLogin = async (sms: boolean) => {

  if (!state.email) {
    state.error = "Please enter your email address";
    return;
  }
  
  state.loading = true;
  if (!state.requested_token && !sms) {
    await loginService.login({
      email: state.email,
      otp: state.token,
      requester: "clients-portal",
      clinic_id: state.clinic
    }).then((result) => {
      if (result.logins) {
        result.logins.forEach((login: any) => {
          state.clinics.push({ value: login.clinic_id, title: login.clinic.name });
          state.clinic = state.clinics[0].value;
        });
      }
      else {
        state.requested_token = true;
        state.loading = false;
      }
      state.error = "";
    }).catch((error) => {
      state.error = error.message;
      state.loading = false;
    });
  }
  else if(!state.requested_token && sms)
  {
    await loginService.login_sms({
      email: state.email,
      otp: state.token,
      requester: "clients-portal",
      clinic_id: state.clinic
    }).then((result) => {
      if (result.logins) {
        result.logins.forEach((login: any) => {
          state.clinics.push({ value: login.clinic_id, title: login.clinic.name });
          state.clinic = state.clinics[0].value;
        });
      }
      else {
        state.requested_token = true;
        state.loading = false;
      }
      state.error = "";
    }).catch((error) => {
      state.error = error.message;
      state.loading = false;
    });
  }
  else {
    await loginService.login_otp({
      email: state.email,
      otp: state.token,
      requester: "clients-portal",
      clinic_id: state.clinic
    }).then((result) => {

      if (result.success) {
        setToken(result.token.access_token);
        setUser(result.user);
        state.error = "";
        userStore.logged_in = true;
        userStore.user = result.user;
        getSettings().then(() => {
          router.replace({ path: '/' });
        })


      }
      else {
        state.error = result.message;
      }

      state.loading = false;
    });
  }
};
</script>

<style scoped>
#container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

#container a {
  text-decoration: none;
}
</style>