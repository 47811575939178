<template>
  <v-dialog
      v-model="dialog"
      width="auto"
  >
    <v-card class="font-grey-dark" height="380" width="400">
      <template v-slot:text>
        <div v-for="(attribute,index) in attributes" :key="`description-${index}`" class="pt-4">
          <p class="font-size-12-600 text-uppercase">{{ attribute.name }}</p>
          <p class="font-black">{{ attribute.value }}</p>
        </div>
      </template>
    </v-card>

    <v-toolbar class="bg-white">
      <v-spacer></v-spacer>
      <v-btn
          class="text-white b-round-20 outline-btn white-btn"
          elevation="0"
          height="32px"
          x-small
          @click="dialog = false"
      >
        Cancel
      </v-btn>
    </v-toolbar>
  </v-dialog>
</template>

<script lang="ts" setup>
import {defineEmits, defineProps, ref, watch} from 'vue';

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
  modelValue: [String, Number],

  attributes: {
    type: Array,
    default: () => []
  },
})

const dialog = ref(props.modelValue);

watch(() => props.modelValue, (newValue) => {
  dialog.value = newValue;
});

watch(dialog, (newValue) => {
  emit('update:modelValue', newValue);
});
</script>