<template>
    <div>
      <div v-if="settingsStore.loading == true" class="d-flex justify-center align-center">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
        <v-row no-gutters v-if="state.items.length > 0">
            <div v-for="item in state.items" :key="item.id" class="w-100">

                <v-col @click="viewNotification(item.id)" v-if="state.showAll || !item.read">
                    <v-sheet class="pa-3 ma-1 text-left sw-notification-read d-flex justify-space-between">
                      <div class="pt-3">
                        <v-icon class="fr-custom-primary">
                          fa-wechat
                        </v-icon>
                      </div>
                      <div class="w-75">
                        <p class="p-text-lg">{{ item.subject }}</p>
                        <p class="p-text-sm mt-2">{{ truncateText(item.content, 32) }}</p>
                        <p class="p-text-xs mt-2" color="light-grey-1">From: {{ item.from }}</p>
                      </div>
                      <div>
                      <v-btn icon @click="markRead(item.id)" variant="text" class="float-right" v-if="!item.read">
                        <v-icon icon="fa-times-circle-o" color="red" size="20"></v-icon>
                      </v-btn>
                    </div>
                    </v-sheet>
                </v-col>
                <v-responsive width="100%"></v-responsive>
            </div>
        </v-row>
        <v-row>
          <v-col>
            <p class="p-text-sm text-center mt-5 text-pink" @click="state.showAll = !state.showAll">
              <u><a>Show {{ state.showAll ? 'Unread' : 'All' }}</a></u>
            </p>
          </v-col>
        </v-row>
    </div>
  </template>

<script lang="ts" setup>
import { useRouter } from "vue-router"
import { useSettingsStore } from "@/api/store/settings"
import { reactive } from "vue";
import { useUserStore } from "@/api/store/user";
import { notificationsService } from '@/api/services/notification';
import { truncateText } from '@/tools/text';

const settingsStore = useSettingsStore();
const userStore = useUserStore();

const router = useRouter();

const state = reactive({
    items: [] as any[],
    showAll: false,
});

const getNotifications = () => {
  notificationsService.index().then((response) => {
    state.items = response.data;
  });
}

getNotifications();

const markRead = (id: number) => {
  settingsStore.loading = true;
  notificationsService.read(id).then((response) => {
    getNotifications();
    settingsStore.loading = false;
  });
}

const viewNotification = (id: number) => {
  markRead(id);
  router.push({ name: 'view-notification', params: { id: id } });
}

</script>