<template>
    <v-card class="mt-2 mx-2 mb-2 pa-4" v-for="file in state.files" :key="file.id">
        <v-row class="my-0" no-gutters>
            <v-col cols="12">
                <v-row class="py-2">
                    <v-col class="pt-3 px-3">
                        <p class="p-text mt-1"><b>{{ file.file_resource.title }}</b></p>
                        <p class="p-text mt-1">File ID <b>#{{ file.file_resource.id }}</b></p>
                        <p class="p-text mt-1">Sent to you: {{ moment(file.created_at).format('YYYY-MM-DD') }}</p>
                    </v-col>
                    <v-col class="text-center">
                        <v-btn density="compact" :color="settingsStore.settings.buttonColor"
                            class="text-white b-round-20 mt-3"
                            @click="openFile(file)">{{  file.file_resource.kind == 'plan' ? 'View' : 'Download'}}</v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-card>
    <v-card class="mt-2 mx-2 mb-2 pa-4" v-if="state.files.length == 0">
        <v-row class="my-0" no-gutters>
            <v-col cols="12">
                <v-row class="py-2">
                    <v-col class="pt-3 px-3">
                        <p class="p-text mt-1">No files available.</p>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-card>
</template>

<script lang="ts" setup>
import { reactive } from 'vue';
import { useSettingsStore } from '@/api/store/settings';
import { useRouter } from 'vue-router';
import moment from 'moment';
import { documentService } from '@/api/services/document';
import { filesService } from '@/api/services/file';

const settingsStore = useSettingsStore();
const router = useRouter();

const state = reactive({
    items: [] as any[],
    files: [] as any[],
});


const getFiles = () => {
    settingsStore.loading = true;
    filesService.index(null).then((response) => {
        //Filter out forms in this view
        state.files = response.filter((file: any) => file.file_resource.kind != 'form');
        settingsStore.loading = false;
    });
}

getFiles();

const openFile = (file: any) => {

    if(file.file_resource.kind == 'plan'){
        router.push({ name: 'document-viewer', params: { id: file.file_resource.model_id } });
    }
    else
    {
        // Handle file through browser window - may require FileSystem integration
        window.open(file.file_resource.file.file_path, '_blank');
    }
}
</script>