<template>
  <div class="position-relative">
    <section class="logo-container">
      <v-sheet
          :class="{
          'clinic-logo-border secondary-background-button-color': supplierId === 'clinic', 
          'logo-default-border': supplierId !== 'clinic'
        }"
          class="logo-container__content"
      >
        <div :style="{backgroundImage: `url(${getClinicLogo})`}" class="logo-container__image"></div>
      </v-sheet>
    </section>

    <v-card class="card-container">
      <section>
        <p class="font-size-18-600">{{ orderType }}</p>
        <p class="font-size-10-400 font-grey-dark">
          Order ID: <span class="font-black font-size-10-600">#{{ order?.id }}</span>
        </p>
      </section>

      <section v-if="orderItems">
        <OrderCardItemDetails
            v-for="(item, index) in orderItems"
            :key="`item-${item.id}`"
            :border="index !== orderItems.length - 1"
            :item="item"
            :order="order"
            :payment-status="getPaymentStatus"
            :supplier-id="supplierId"
            :supplier-order="supplierOrder"
        />
      </section>

      <section class="mt-5 font-size-12-600">
        <div class="d-flex align-center justify-space-between">
          <p>Discount Code</p>
          <p class="font-size-16-600">{{ orderDetails.discount_code }}</p>
        </div>
        <div class="d-flex align-center justify-space-between">
          <p>Discount</p>
          <p class="font-size-16-600">{{ orderDetails.discount }}</p>
        </div>
        <div class="d-flex align-center justify-space-between">
          <p>VAT</p>
          <p class="font-size-16-600">{{ orderDetails.vat }}</p>
        </div>
        <div v-if="hasMultipleOrders" class="d-flex align-center justify-space-between">
          <p>Subtotal</p>
          <p class="font-size-16-600">{{ orderDetails.total }}</p>
        </div>
      </section>
    </v-card>
  </div>
</template>

<script lang="ts" setup>
import {computed, defineProps} from "vue";
import OrderCardItemDetails from "@/views/OrdersComponents/OrderDetails/OrderCardItemDetails.vue";
import moment from "moment";

const props = defineProps({
  order: {
    type: Object,
  },
  orderItems: {
    type: Array,
  },
  supplierOrder: {
    type: Object,
  },
  supplierId: {
    type: String,
  },
  clinicLogo: {
    type: Object,
  },
  orderType: {
    type: String,
  },
  hasMultipleOrders: {
    type: Boolean,
  }
});

const getClinicLogo = computed(() => {
  if (props.supplierId == 'clinic') {
    return props.clinicLogo?.file.file_path ?? ''
  }

  return '/img/tnd_hands_logo.png'
})

const getPaymentStatus = computed(() => {
  const paymentStatus = props.order.payment_status;
  const orderCreationDate = moment(props.order.created_at);

  // Calculation of overdue time limit
  const overdueTimeLimit = moment(orderCreationDate).add(24, 'hours');

  const isOverdue = paymentStatus === 'not_paid' && moment().isAfter(overdueTimeLimit);

  switch (props.order.payment_status) {
    case 'paid':
      return {
        color: 'font-success',
        status: '(Paid)'
      }
    case 'partial':
      return {
        color: 'font-warning',
        status: '(Part Paid)'
      }
    case 'processing':
      return {
        color: 'font-info',
        status: '(Processing)'
      }
    case 'chargeback':
    case 'failed':
      return {
        color: 'font-success',
        status: '(Failed)'
      }
    case 'not_paid':
      if (isOverdue) {
        return {
          color: 'font-danger',
          status: '(Overdue)'
        };
      } else {
        return {
          color: 'font-danger',
          status: '(Not Paid)'
        };
      }
    default:
      return {
        color: '',
        status: ''
      }
  }
})

const orderDetails = computed(() => {
  return {
    vat: props.supplierOrder?.tax ?? '-',
    discount: `${props.supplierOrder?.currency_symbol}${props.supplierOrder?.discount}` ?? '-',
    discount_code: props.supplierOrder?.discount_code ?? '-',
    total: `${props.supplierOrder?.currency_symbol}${props.supplierOrder?.total}` ?? '-',
  }
})
</script>

<style lang="scss" scoped>
.logo-container {
  position: absolute;
  left: 0;
  right: 0;
  top: -80px;
  z-index: 1;

  &__content {
    margin: auto;
    border-radius: 30%;
    padding: 10px;
    width: 100px;
    height: 100px;
  }

  &__image {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .logo-default-border {
    border: 1px solid #304978;
    background: #3A5485;
  }
}

.card-container {
  border-radius: 10px;
  box-shadow: 0.5px 0.5px 15px 0px #0000000D !important;
  border: 1px solid #F0F0F0;
  padding: 20px;
  margin-top: 95px;
}
</style>