import { HttpService } from "./http";

class ActivityService extends HttpService {
  public constructor() {
    super("/client-app/client/activities");
  }

  public index(filters: string | null = null): Promise<any> {

    let route = this.route + "/";

    if(filters ) {
        route += "?";
        route += filters;
    }

    return this.http
      .get(route)
      .then(this.handleResponse.bind(this))
      .catch(this.handleError.bind(this));
  }
}

export const activityService: ActivityService = new ActivityService();
