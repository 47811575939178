<template>
  <v-container class="px-0 position-relative" style="background-color:#F0F0F0; min-height:100vh">
    <v-card v-if="getServices.length > 0" class="card-container">
      <v-card-title class="font-size-18-600 pt-0 pl-0">
        Services
      </v-card-title>

      <ShopCartServicePackageDetails
          v-for="(service, index) in getServices"
          :key="`service-${index}`"
          :disabled="loadingOnRemove"
          :item="service"
          class="pb-5"
          @delete="removeFromCart(service.cart_id, service.item_id)"
      />
    </v-card>

    <v-card v-if="getProducts.length > 0" class="card-container">
      <v-card-title class="font-size-18-600 pt-0 pl-0">
        Products
      </v-card-title>

      <ShopCartProductDetails
          v-for="product in getProducts"
          :key="product.id"
          :disabled="loadingOnRemove"
          :item="product"
          class="pb-5"
          @delete="removeFromCart(product.cart_id, product.item_id)"
      >
        <quantity-editor
            v-model="product.quantity"
            :disable="loadingOnChangeQuantity"
            @update:model-value="debouncedChangeQuantity(product, product.quantity)"
        />
      </ShopCartProductDetails>
    </v-card>

    <section class="pt-12">
      <ButtonFixed
          :disabled="cartStore.items.length === 0"
          :text="`Proceed to Order Summary (${getTotalItemsInCart})`"
          custom-class="text-white primary-button"
          @click="router.push({ name: 'order-create' })"
      />
    </section>

    <v-card v-if="cartStore.items.length == 0" class="mt-4 mx-4 mb-3">
      <v-alert v-if="!settingsStore.loading" type="info" variant="tonal">No items in cart</v-alert>
    </v-card>
  </v-container>
</template>

<script lang="ts" setup>
import {computed, onMounted, shallowRef, inject} from 'vue';
import {useRouter} from 'vue-router';
import {useCartStore} from '@/api/store/cart';
import {cartService} from '@/api/services/cart';
import {useSettingsStore} from '@/api/store/settings';

import QuantityEditor from '@/components/QuantityEditor.vue';
import ShopCartProductDetails from "@/views/ShopComponents/ShopCart/ShopCartProductDetails.vue";
import ButtonFixed from "@/components/custom/ButtonFixed.vue";
import ShopCartServicePackageDetails from "@/views/ShopComponents/ShopCart/ShopCartServicePackageDetails.vue";

import debounce from 'lodash/debounce';

const settingsStore = useSettingsStore();
const cartStore = useCartStore();
const router = useRouter();

const snackBarPlugin = inject('snackbar');

const loadingOnRemove = shallowRef();
const loadingOnChangeQuantity = shallowRef();

const getCarts = async () => {
  try {
    const response = await cartService.index();

    if (Array.isArray(response.data)) {
      const newItems = [];

      response.data.forEach((product: any) => {
        product.items.forEach((item: any) => {
          newItems.push({
            ...item,
            ...product,
            item_id: item.id,
          });
        });
      });

      cartStore.items = newItems;

      // Now check if any `shipping_method` item exists without `Product` items
      removeShippingIfNoProducts(newItems);
    }
  } catch (error) {
    console.log(error);
  } finally {
    settingsStore.loading = false;
  }
};

const removeShippingIfNoProducts = (items: any[]) => {
  // Check for the shipping_method item
  const shippingMethodItem = items.find(item => item.product?.type === 'shipping_method');

  // Check if there are no Product items in the cart
  const hasProducts = items.some(item => item.product.type === 'clinic_product');

  if (shippingMethodItem && !hasProducts) {
    // Remove the shipping address automatically
    removeFromCart(shippingMethodItem.cart_id, shippingMethodItem.item_id, true);
  }
};

const removeFromCart = (cartId: any, itemId: any, shippingMethodWithoutProduct = false) => {
  loadingOnRemove.value = true;
  cartService.removeItem(cartId, itemId).then((response: any) => {
    getCarts();
    loadingOnRemove.value = false;

    if (!shippingMethodWithoutProduct) {
      snackBarPlugin.successSnackbar('Item removed from cart');
    }
  }).catch((error: any) => {
    console.log(error);
  });
};

const changeQuantity = (item: any, quantity: number) => {
  loadingOnChangeQuantity.value = true;
  if (quantity <= 0) {
    removeFromCart(item.cart_id, item.item_id);
    loadingOnChangeQuantity.value = false;
    return;
  }
  cartService.changeQuantity(item.cart_id, item.item_id, quantity).then((response: any) => {
    getCarts();
    loadingOnChangeQuantity.value = false;
  }).catch((error: any) => {
    console.log(error);
  });
};

// Debounce the changeQuantity function
const debouncedChangeQuantity = debounce(changeQuantity, 500);

// Render products
const getProducts = computed(() => {
  return cartStore.items.filter((item: any) => item.clean_product_type === 'Product');
})

// Render Services
const getServices = computed(() => {
  return cartStore.items.filter((item: any) => item.clean_product_type === 'ClinicService');
})

const getTotalItemsInCart = computed(() => {
  let totalItems = 0;

  cartStore.items.filter((item) => {
    return item.product?.type != 'shipping_method';
  }).forEach((item: any) => {
    totalItems += item.quantity;
  });

  return totalItems > 1 ? `${totalItems} items` : `${totalItems} item`;
})

onMounted(() => {
  settingsStore.loading = true;
  getCarts();
})
</script>

<style lang="scss" scoped>
.card-container {
  box-shadow: 0.5px 0.5px 15px 0px #0000000D !important;
  border: 1px solid #F0F0F0;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 10px 20px;
}
</style>
