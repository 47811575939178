import { HttpService } from "./http";

class FilesService extends HttpService {
  public constructor() {
    super("/client-app/client/files");
  }

  public index(filters: string | null = null): Promise<any> {

    let route = this.route + "/";

    if(filters ) {
        route += "?";
        route += filters;
    }

    return this.http
      .get(route)
      .then(this.handleResponse.bind(this))
      .catch(this.handleError.bind(this));
  }

  public getFile(id: number): Promise<any> {
    return this.http
      .get(this.route + "/get/" + id)
      .then(this.handleResponse.bind(this))
      .catch(this.handleError.bind(this));
  }
}

export const filesService: FilesService = new FilesService();
