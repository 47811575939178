<template>
  <v-row class="mt-6 px-5 mx-0">
    <v-col class="px-3">
      <v-card class="sw-card primary-background-button-color" height="250" style="color: #fff" width="100%"
              @click="router.push('appointments')">
        <v-card-title class="text-white font-weight-bold text-subtitle-1">Events</v-card-title>
        <v-card-text v-if="!state.event" class="text-center">
          <v-icon class="text-white" size="60">fa-calendar</v-icon>
          <p class="text-white font-weight-bold text-subtitle-2 mt-5">No upcoming events</p>
        </v-card-text>
        <v-card-text v-else class="text-left">
          <p class="p-1 mb-3">{{ state.event.name }}</p>
          <div class="box-indent scroll-note">
            <p class="text-white font-weight-bold text-subtitle-2">{{ state.event.practitioner?.name }}</p>
            <p class="text-body-2 mt-2">{{ moment(state.event.start_date).format('YYYY-MM-DD HH:MM') }}</p>
            <p class="text-body-2 mt-2">{{ state.event.duration ?? '30' }} minutes</p>
            <p class="text-body-2 mt-2">{{ state.event.model?.method }}</p>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col class="px-3">
      <v-card class="sw-card secondary-background-button-color" height="250" style="color: #fff" width="100%">
        <v-card-title class="text-white font-weight-bold text-subtitle-1">Activity</v-card-title>
        <v-card-text v-if="!state.notification" class="text-center">
          <v-icon class="text-white" size="60">fa-bell</v-icon>
          <p class="text-white font-weight-bold text-subtitle-2 mt-5">No recent activity</p>
        </v-card-text>
        <v-card-text v-else class="text-left">
          <p class="p-1 mb-3">{{ state.notification.subject }}</p>
          <div class="box-indent scroll-note">
            <p class="text-white font-weight-bold text-subtitle-2">{{ state.notification.content }}</p>
            <p class="text-body-2 mt-2">From: {{ state.notification.from }}</p>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script lang="ts" setup>
import {useRouter} from 'vue-router';
import {notificationsService} from '@/api/services/notification';
import {consultationService} from '@/api/services/consultation';
import {reactive} from 'vue';
import moment from 'moment';

const state = reactive({
  notification: null as any,
  event: null as any,
});

const router = useRouter();

const getNotification = () => {
  notificationsService.mostRecent().then((response) => {
    state.notification = response;
  });
}

const getEvent = () => {
  consultationService.mostRecent().then((response) => {
    state.event = response;
  });
}

getNotification();
getEvent();
</script>

<style>
.sw-card {
  border-radius: 25px;
}

.box-indent {
  border-left: 5px solid white;
  margin-left: 5px;
  padding-left: 5px;
}

.scroll-note {
  font-size: 6px;
  max-height: 200px;
  overflow-y: auto;
}
</style>