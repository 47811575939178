<template>
  <section v-if="!isShipping" class="order-card-item-details">
    <v-sheet v-if="getImage" class="logo-container m-0">
      <div :style="{backgroundImage: `url(${getImage})`}" class="logo-container__image"></div>
    </v-sheet>

    <v-sheet v-else :class="{'secondary-background-button-color': !item?.supplier }" class="icon-container">
      <Icon :icon="getIcon"/>
    </v-sheet>

    <v-card-text class="card-description pa-0 ">
      <section class="d-flex justify-space-between">
        <div>
          <p class="font-size-12-600">{{ getTitle }}</p>
        </div>
        <div :class="{'disabled': disabled}" @click="emit('delete')">
          <Icon icon="trash-icon"/>
        </div>
      </section>

      <section class="d-flex justify-space-between mt-3">
        <div class="mt-1 ga-2 font pr-1">
          <p>Description:</p>
          <div class="font-grey-dark">
            {{ getDescription }}
          </div>
        </div>
        <div>
          <p class="font-size-16-600">{{ getPrice }}</p>
        </div>
      </section>
    </v-card-text>
  </section>
</template>

<script lang="ts" setup>
import {defineProps, computed} from 'vue';
import Icon from "@/components/Icons/Icon.vue";
import {defineEmits} from 'vue';

const emit = defineEmits(['delete']);

const props = defineProps({
  item: {
    type: Object,
  },

  disabled: {
    type: Boolean,
    default: false
  }
});

const getTitle = computed(() => {
  if (props.item?.product) {
    return props.item.product.name;
  }

  return props.item?.name;
})

const getImage = computed(() => {
  if (props.item?.product && props.item?.product?.images !== undefined) {
    return props.item?.product?.images[0]?.full ?? null;
  }

  return null;
})

const getIcon = computed(() => {
  const type = props.item.product.type ?? null;

  if (type === 'clinic_service' || type === 'service') {
    return 'service-icon';
  }

  return null;
})

const getPrice = computed(() => {
  return props.item.price_display ?? '-';
})

const isShipping = computed(() => {
  return props.item.product.type === 'shipping_method';
})

const getDescription = computed(() => {
  return props.item.product.description ?? '';
})
</script>

<style lang="scss" scoped>
.order-card-item-details {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 20px;

  .logo-container {
    width: 126.61px;
    height: 122.57px;

    &__image {
      border-radius: 5px;
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}

.icon-container {
  border-radius: 5px;
  width: 126.61px;
  height: 122.57px;
  background: #3A5485;
  display: flex;
  align-items: center;
  justify-content: center;
}

.supplier {
  background: #3A5485;
  color: white;
  padding: 2px 6px;
  border-radius: 30px;
  text-align: center;
  width: fit-content;
}

.disabled {
  pointer-events: none;
}

.font {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}
</style>