<template>
    <QuickMenu />
    <NewsPanel/>
    <EventsPanel />
</template>

<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
import HomeNavigator from './HomeComponents/HomeNavigator.vue';
import NewsPanel from '@/components/Layout/LayoutV2/NewsPanel.vue';
//import WellnessPanel from '@/components/Layout/LayoutV2/WellnessPanel.vue';
import EventsPanel from '@/components/Layout/LayoutV2/EventsPanel.vue';
import { useSettingsStore } from '@/api/store/settings';
import { appService } from '@/api/services/app';
import router from '@/router';

const settingsStore = useSettingsStore();

const QuickMenu = defineAsyncComponent(() => import('@/components/Layout/LayoutV' + settingsStore.settings.layout + '/QuickMenu.vue'));
</script>

<style>
.news-item {
  background-image: url("@/assets/newsitem.png");
  background-repeat: repeat-x;
  background-size: auto;
}
</style>