<template>
    <v-row class="text-center my-3">
        <v-col>
            <v-btn class="b-round-20 w-75" :color="settingsStore.settings.secondaryColor"
                :variant="state.showCompleted ? 'outlined' : 'flat'" @click="toggleView">Incomplete</v-btn>
        </v-col>
        <v-col>
            <v-btn class="b-round-20 w-75" :color="settingsStore.settings.secondaryColor"
                :variant="!state.showCompleted ? 'outlined' : 'flat'" @click="toggleView">Complete</v-btn>
        </v-col>
    </v-row>
    <v-card class="mt-2 mx-2 mb-2 pa-4" v-for="document in filteredItems" :key="document.id">
        <v-row class="my-0" no-gutters>
            <v-col cols="12">
                <v-row class="py-2">
                    <v-col class="pt-3 px-3">
                        <p class="p-text-lg mt-1">{{ moment(document.created_at).format('DD MMM YYYY') }}</p>
                        <p class="p-text mt-2"><b>{{ document.file_resource.title }}</b></p>
                    </v-col>
                    <v-col class="text-center">
                        <v-btn :color="settingsStore.settings.buttonColor" class="text-white b-round-20 mt-3"
                            @click="router.push({ name: 'document-viewer', params: { id: document.file_resource.file.id } })">{{
                                state.showCompleted ? 'View' : 'Complete Now' }}</v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-card>
    <v-card class="mt-2 mx-2 mb-2 pa-4" v-if="state.items.length == 0">
        <v-row class="my-0" no-gutters>
            <v-col cols="12">
                <v-row class="py-2">
                    <v-col class="pt-3 px-3">
                        <p class="p-text mt-1" v-if="state.filesLoaded">No forms available.</p>
                        <div class="text-center" v-else>
                            <v-progress-circular indeterminate color="primary"></v-progress-circular>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-card>
</template>

<script lang="ts" setup>
import { reactive, computed } from 'vue';
import { useSettingsStore } from '@/api/store/settings';
import { useRouter } from 'vue-router';
import moment from 'moment';
import { filesService } from '@/api/services/file';

const settingsStore = useSettingsStore();
const router = useRouter();

const state = reactive({
    items: [] as any[],
    showCompleted: false,
    filesLoaded: false
});

const filteredItems = computed(() => {
    return state.items.filter(item => {
        const hasCompletedDocument = item.file_resource?.file?.client_documents[0]?.status === 'complete';
        return state.showCompleted ? hasCompletedDocument : !hasCompletedDocument;
    });
});

const getFiles = () => {
    settingsStore.loading = true;
    filesService.index(null).then((response) => {
        //Filter out forms in this view
        state.items = response.filter((file: any) => file.file_resource && (
            file.file_resource.kind == 'form'));

    }).catch((error) => {
        console.log(error);
    }).finally(() => {
        state.filesLoaded = true;
        settingsStore.loading = false;
    });
}

const toggleView = () => {
    state.showCompleted = !state.showCompleted;
}

getFiles();
</script>